import styled from 'styled-components'
import { border, space } from 'styled-system'

const Divider = styled.span`
  display: flex;
  border-top: 1px solid;

  ${border}
  ${space}
`

export default Divider
