import React from 'react'

import { IconWrapper } from '../../common/mixins'

const RedditIcon = () => (
  <IconWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="M44.8 19.3c-1.6 0-3 .7-4.1 1.7-3.8-2.7-9-4.4-14.7-4.6l3-13.6L38.4 5c0 2.3 1.9 4.3 4.2 4.3 2.4 0 4.3-2 4.3-4.3S45 .6 42.6.6c-1.7 0-3.1 1-3.8 2.4L28.4.7c-.5-.1-1 .2-1.2.8l-3.3 15c-5.7.2-10.8 2-14.6 4.7-1-1.1-2.5-1.8-4.1-1.8-6 0-7.9 8.1-2.5 10.9-.1.7-.2 1.7-.2 2.6C2.5 42 12.6 49.4 25 49.4c12.5 0 22.6-7.4 22.6-16.5 0-.9-.1-1.9-.3-2.7 5.4-2.8 3.4-10.9-2.5-10.9zM11.4 30.8c0-2.4 1.9-4.3 4.3-4.3 2.3 0 4.2 1.9 4.2 4.3s-1.9 4.3-4.2 4.3c-2.4-.1-4.3-2-4.3-4.3zm23 10.1c-3.9 4-14.9 4-18.8 0-.4-.4-.4-1.1 0-1.5.4-.4 1-.4 1.4 0 3 3.1 12.9 3.2 16 0 .4-.4 1-.4 1.4 0 .4.5.4 1.2 0 1.5zm-.1-5.9c-2.3 0-4.2-1.9-4.2-4.3s1.9-4.3 4.2-4.3c2.4 0 4.3 1.9 4.3 4.3S36.7 35 34.3 35z" />
    </svg>
  </IconWrapper>
)

export default RedditIcon
