import { URL_PATHS } from '../../constants/urlPaths'

const mapNewsToList = (news) =>
  news ? news.map(
    ({ node: { id, date, pseudoExcerpt, featuredImage, title, slug } }) => {
      const image = featuredImage?.imageFile?.childImageSharp.fluid

      return {
        id,
        date,
        description: pseudoExcerpt,
        fluid: image,
        label: title,
        to: `${URL_PATHS.BLOG}/${slug}`,
      }
    },
  ) : []

export default mapNewsToList
