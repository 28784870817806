import styled from 'styled-components'
import Link from 'gatsby-link'

import { transition } from '../../common/mixins'

const StyledTagsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -0.625rem -0.3125rem 0 -0.3125rem;
  width: calc(100% + (0.3125rem * 2));
`

const StyledTagsItem = styled.li`
  margin: 0.625rem 0.3125rem 0 0.3125rem;
`

const StyledTag = styled(Link)`
  display: flex;
  align-items: center;
  text-align: center;
  background: ${({ theme }) => theme.colors.lightYellow};
  padding: 0 0.875rem;
  ${transition}

  &:hover {
    background: ${({ theme }) => theme.colors.yellow};
  }
`

const StyledTagLabel = styled.span`
  letter-spacing: 0.21px;
  font-size: 0.75rem;
  line-height: 1.875rem;
`

export { StyledTagsList, StyledTagsItem, StyledTag, StyledTagLabel }
