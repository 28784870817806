import React from 'react'
import PropTypes from 'prop-types'

import FacebookIcon from '../Icons/FacebookIcon'
import TwitterIcon from '../Icons/TwitterIcon'
import LinkedInIcon from '../Icons/LinkedInIcon'
import GoogleIcon from '../Icons/GoogleIcon'
import PinterestIcon from '../Icons/PinterestIcon'
import RedditIcon from '../Icons/RedditIcon'
import MailIcon from '../Icons/MailIcon'
import {
  StyledSocialMedia,
  StyledSocialMediaItem,
  StyledSocialMediaBox,
  StyledSocialMediaIcon,
} from './styles'

const SocialMedia = ({ socialMedia }) => {
  const icons = {
    Facebook: <FacebookIcon key="Facebook" />,
    Twitter: <TwitterIcon key="Twitter" />,
    LinkedIn: <LinkedInIcon key="LinkedIn" />,
    Google: <GoogleIcon key="Google" />,
    Pinterest: <PinterestIcon key="Pinterest" />,
    Reddit: <RedditIcon key="Reddit" />,
    Mail: <MailIcon key="Mail" />,
  }

  return (
    <StyledSocialMedia className="uiAnimBottom">
      {socialMedia.map(({ link }) => {
        const { title, component: Component, ...rest } = link

        return (
          <StyledSocialMediaItem key={title}>
            <StyledSocialMediaBox>
              <Component {...rest}>
                <StyledSocialMediaIcon>{icons[title]}</StyledSocialMediaIcon>
              </Component>
            </StyledSocialMediaBox>
          </StyledSocialMediaItem>
        )
      })}
    </StyledSocialMedia>
  )
}

export default SocialMedia

SocialMedia.propTypes = {
  socialMedia: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      link: PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        component: PropTypes.ReactNode,
        media: PropTypes.string,
        description: PropTypes.string,
      }),
    }),
  ),
}
