import styled from 'styled-components'

import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const StyledSocialMedia = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0.0625rem 0 0 0;

  @media ${media.tablet} {
    flex-direction: column;
  }
`

const StyledSocialMediaItem = styled.li`
  display: flex;
  width: 2.75rem;
  height: 2.75rem;
  margin: -0.0625rem 0 0 -0.0625rem;

  @media ${media.tablet} {
    margin: 0;
    &:not(:first-child) {
      margin-top: -0.0625rem;
    }
  }
`

const StyledSocialMediaIcon = styled.span`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  ${transition}
`

const StyledSocialMediaBox = styled.span`
  display: flex;
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey3};
  ${transition}

  button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
    border-color: ${({ theme }) => theme.colors.black};
    ${StyledSocialMediaIcon} {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }
`

export {
  StyledSocialMedia,
  StyledSocialMediaItem,
  StyledSocialMediaBox,
  StyledSocialMediaIcon,
}
