import React from 'react'
import PropTypes from 'prop-types'

import { StyledBlogHeading, StyledBlogHeadingDate } from './styles'
import { Heading8 } from '../Typography/Typography'
import { formatDate } from '../../common/utils'

const BlogHeading = ({ date, title }) => {
  const newDate = new Date(date)

  return (
    <StyledBlogHeading className="uiAnimBottom">
      <StyledBlogHeadingDate>{formatDate(newDate)}</StyledBlogHeadingDate>
      <Heading8
        as="h1"
        fontFamily="georgia"
        fontWeight="bold"
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </StyledBlogHeading>
  )
}

export default BlogHeading

BlogHeading.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
