import React from 'react'
import PropTypes from 'prop-types'

import { StyledContent } from './styles'

const Content = ({ children }) => (
  <StyledContent className="uiAnimCustomBottom">{children}</StyledContent>
)

export default Content

Content.propTypes = {
  children: PropTypes.node.isRequired,
}
