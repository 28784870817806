import React from 'react'

import { IconWrapper } from '../../common/mixins'

const GoogleIcon = () => (
  <IconWrapper fontSize="1.2" width="1.4375">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path d="M16.003906 14.0625v4.203125h5.988282c-.78125 2.546875-2.910157 4.371094-5.988282 4.371094-3.664062 0-6.636718-2.972656-6.636718-6.636719 0-3.664062 2.96875-6.636719 6.636718-6.636719 1.648438 0 3.152344.605469 4.3125 1.601563l3.09375-3.097656C21.457031 6.085938 18.855469 5 16.003906 5 9.925781 5 5 9.925781 5 16s4.925781 11 11.003906 11c9.234375 0 11.273438-8.636719 10.367188-12.921875z" />
    </svg>
  </IconWrapper>
)

export default GoogleIcon
