import React from 'react'

import { IconWrapper } from '../../common/mixins'

const PinterestIcon = () => (
  <IconWrapper width="1.4375">
    <svg
      viewBox="-62 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M60.945312 278.21875c.640626-1.597656 7.050782-26.230469 7.5-27.898438-10.007812-15.058593-21.125-64.371093-12.597656-104.398437 9.199219-58.730469 71.4375-87.601563 130.199219-87.601563v-.109374c73.570313.046874 128.640625 40.980468 128.699219 111.476562.046875 55.179688-33.195313 128.117188-89.957032 128.117188h-.042968c-20.257813 0-45.90625-9.1875-52.632813-18.210938-7.761719-10.398438-9.667969-23.230469-5.566406-36.941406 10.050781-32.082032 22.867187-70.511719 24.363281-96.136719 1.386719-24.183594-15.773437-39.917969-38.027344-39.917969-16.746093 0-38.496093 9.726563-49.335937 37.058594-8.953125 22.707031-8.761719 46.480469.585937 72.671875 3.644532 10.238281-16.15625 76.984375-22.5 98.71875-15.761718 53.992187-37.339843 122.304687-32.726562 160.347656L53.359375 512l22.367187-29.3125c30.953126-40.519531 62.957032-145.332031 71.484376-170.835938C172.421875 344.5 224.921875 345.4375 231.042969 345.4375c75.183593 0 160.4375-74.65625 158.019531-178.5625C386.941406 75.753906 320.253906 0 200.429688 0v.117188c-113.976563 0-180.5 60.835937-196.785157 137.703124-14.914062 71.273438 18.253907 125.519532 57.300781 140.398438zm0 0" />
    </svg>
  </IconWrapper>
)

export default PinterestIcon
