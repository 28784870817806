import styled from 'styled-components'

import { date } from '../../common/mixins'

const StyledBlogHeading = styled.div`
  max-width: 58.5rem;
`

const StyledBlogHeadingDate = styled.time`
  display: block;
  margin-bottom: 1.25rem;
  ${date}
`

export { StyledBlogHeading, StyledBlogHeadingDate }
