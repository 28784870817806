import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import isEmpty from 'lodash/isEmpty'
import { useInView } from 'react-intersection-observer'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Inner from '../components/Inner'
import Container from '../components/Container'
import Divider from '../components/Divider'
import BlogHeading from '../components/BlogHeading/BlogHeading'
import Tags from '../components/Tags/Tags'
import Content from '../components/Content/Content'
import { Typo3, Heading7 } from '../components/Typography/Typography'
import NewsSlider from '../components/Sliders/NewsSlider/NewsSlider'
import OtherModelsSlider from '../components/Sliders/OtherModelsSlider/OtherModelsSlider'
import mapNewsToList from '../common/queries/mapNewsToList'
import SocialMedia from '../components/SocialMedia/SocialMedia'
import { BG_TYPES } from '../constants/bgTypes'
import { HEADER_TYPES } from '../constants/headerTypes'
import { mapSocialMedia } from '../components/SocialMedia/mock'
import { URL_PATHS } from '../constants/urlPaths'
import { formatCmsContent, bgTheme } from '../common/utils'
import mapOtherModelsToList from '../common/queries/mapOtherModelsToList'
import NewsListProvider from '../providers/NewsListProvider'

const SingleNewsTemplate = ({ pageContext, data }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  const { sliderNewsList, newsUrlList } = pageContext
  const { post } = data.wp
  const relatedModels = post.postAcf?.models
  const { edges: models } = data.wp.models
  const seoData = post.seo
  let url

  const filteredSliderNewsList = sliderNewsList.filter(
    ({ node: { slug } }) => slug !== post.slug,
  )

  const otherModels = useMemo(
    () =>
      relatedModels
        ? relatedModels
            .map(({ id, acf, title, slug, featuredImage }) => ({
              node: {
                id,
                acf,
                title,
                slug,
                featuredImage,
              },
            }))
            .filter((model) => !model.node.acf.comingSoon)
        : models.filter((model) => !model.node.acf.comingSoon),
    [relatedModels, models],
  )

  const mappedOtherModels = mapOtherModelsToList(otherModels)
  const {
    otherModelsSliderImages,
    otherModelsSliderDetails,
  } = mappedOtherModels

  const news = mapNewsToList(filteredSliderNewsList)

  const otherModelsBg = post.postAcf.modelsListBg
    ? bgTheme(post.postAcf.modelsListBg)
    : BG_TYPES.white
  const newsBg = post.postAcf.newsListBg
    ? bgTheme(post.postAcf.newsListBg)
    : BG_TYPES.lightGrey

  const tags = post.terms
    .filter(
      ({ slug, taxonomy }) =>
        slug !== 'uncategorized' &&
        (taxonomy.node.name === 'post_tag' ||
          taxonomy.node.name === 'category'),
    )
    .map(({ name, slug, taxonomy }) => {
      url =
        taxonomy.node.name === 'post_tag'
          ? `${URL_PATHS.TAG}/${slug}`
          : `${URL_PATHS.CATEGORY}/${slug}`

      return {
        url,
        tag: name[0].toUpperCase() + name.substring(1),
      }
    })

  const socialMedia = mapSocialMedia(post)

  return (
    <NewsListProvider list={newsUrlList}>
      <Layout headerType={HEADER_TYPES.alt} headerDarkLogo>
        <SEO title={seoData?.title} data={seoData} />
        <Container
          bg={BG_TYPES.lightGrey}
          mt={['4.875rem', '', '', '0']}
          pt={['1.875rem', '', '2.625rem']}
          pb={['2.875rem']}
        >
          <Inner>
            <Container mb={['1.875rem', '2.75rem']}>
              <BlogHeading date={post.date} title={post.title} />
            </Container>

            <Container style={{ maxHeight: '100%' }} className="uiAnimBottom">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={post.featuredImage.imageFile.childImageSharp.fluid}
                objectFit="cover"
                objectPosition="50% 10%"
                style={{ maxHeight: '30.3125rem' }}
              />
            </Container>

            <Container display={['none', '', '', 'block']}>
              <Inner position="relative">
                <Container position="absolute" top="3rem" left="0">
                  <SocialMedia socialMedia={socialMedia} />
                </Container>
              </Inner>
            </Container>

            <Content>
              <div
                dangerouslySetInnerHTML={{
                  __html: formatCmsContent(post.content, newsUrlList),
                }}
              />

              <Container mt="1.875rem" display={['block', '', 'none']}>
                <SocialMedia socialMedia={socialMedia} />
              </Container>
              {!isEmpty(tags) && (
                <>
                  <Divider
                    mt={['1.875rem', '', '1.75rem']}
                    borderColor="lightGrey2"
                  />

                  <Container
                    mt={['1.875rem', '', '1.375rem']}
                    mb={['1.875rem']}
                  >
                    <Typo3 as="h3">Tags &amp; Categories</Typo3>
                  </Container>

                  <Tags tags={tags} />
                </>
              )}
            </Content>
          </Inner>
        </Container>

        <Container color={otherModelsBg === BG_TYPES.black ? 'white' : 'black'}>
          <div className="uiAnimBottom">
            <div ref={ref}>
              {inView ? (
                <OtherModelsSlider
                  bg={otherModelsBg || BG_TYPES.white}
                  images={otherModelsSliderImages}
                  details={otherModelsSliderDetails}
                />
              ) : null}
            </div>
          </div>
        </Container>

        <Container
          pt={['3.375rem', '', '5rem']}
          pb={['2.875rem', '', '6.25rem']}
          bg={newsBg}
          overflow="hidden"
          color={newsBg === BG_TYPES.black ? 'white' : 'black'}
        >
          <Container>
            <Inner>
              <Container mb={['2.5rem', '3.375rem']}>
                <Heading7 as="h3" textAlign="center" className="uiAnimBottom">
                  Browse More News
                </Heading7>
              </Container>
              <NewsSlider {...{ news }} />
            </Inner>
          </Container>
        </Container>
      </Layout>
    </NewsListProvider>
  )
}

export default SingleNewsTemplate

export const pageQuery = graphql`
  query($newsId: ID!) {
    wp {
      post(id: $newsId, idType: ID) {
        id
        date
        slug
        title
        content
        featuredImage {
          mediaItemUrl
          imageFile {
            childImageSharp {
              fluid(sizes: "(max-width: 1140px) calc(100vw - 60px), 1140px") {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        terms {
          ... on wp_Tag {
            id
            name
            slug
            taxonomy {
              node {
                name
              }
            }
          }
          ... on wp_Category {
            id
            name
            slug
            taxonomy {
              node {
                name
              }
            }
          }
          ... on wp_City {
            id
            name
            taxonomy {
              node {
                name
              }
            }
          }
        }
        postAcf {
          modelsListBg
          newsListBg
          models {
            ... on wp_Model {
              id
              title
              slug
              featuredImage {
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 960) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              acf {
                description
                comingSoon
              }
            }
          }
        }
        seo {
          ...seoFragment
        }
      }
      models(first: 10) {
        edges {
          node {
            id
            title
            slug
            featuredImage {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            acf {
              ...modelDetailsFragment
              comingSoon
            }
          }
        }
      }
    }
  }
`
