import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  RedditShareButton,
} from 'react-share'

import { URL_PATHS } from '../../constants/urlPaths'

export const mapSocialMedia = (post) => {
  const url = `https://${process.env.GATSBY_URL}${URL_PATHS.NEWS}/${post.slug}`

  return [
    {
      img: 'FacebookIcon',
      link: {
        url,
        title: 'Facebook',
        component: FacebookShareButton,
      },
    },
    {
      img: 'TwitterIcon',
      link: {
        url,
        title: 'Twitter',
        component: TwitterShareButton,
      },
    },
    {
      img: 'LinkedInIcon',
      link: {
        url,
        title: 'LinkedIn',
        component: LinkedinShareButton,
      },
    },
    {
      img: 'PinterestIcon',
      link: {
        url,
        title: 'Pinterest',
        component: PinterestShareButton,
        media: post.featuredImage.mediaItemUrl,
        description: post.seo.title,
      },
    },
    {
      img: 'RedditIcon',
      link: {
        url,
        title: 'Reddit',
        component: RedditShareButton,
      },
    },
    {
      img: 'MailIcon',
      link: {
        url: `mailto:?subject=${post.seo.title}&body=${url}`,
        title: 'Mail',
        component: EmailShareButton,
      },
    },
  ]
}
