import React from 'react'

import { IconWrapper } from '../../common/mixins'

const FacebookIcon = () => (
  <IconWrapper fontSize="1.1" width="1.375">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M288 176v-64c0-17.664 14.336-32 32-32h32V0h-64c-53.024 0-96 42.976-96 96v80h-64v80h64v256h96V256h64l32-80h-96z" />
    </svg>
  </IconWrapper>
)

export default FacebookIcon
