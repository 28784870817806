import React from 'react'

import { IconWrapper } from '../../common/mixins'

const LinkedInIcon = () => (
  <IconWrapper fontSize="0.8" width="1.4375">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M0 160h114.496v352H0zM426.368 164.128c-1.216-.384-2.368-.8-3.648-1.152-1.536-.352-3.072-.64-4.64-.896-6.08-1.216-12.736-2.08-20.544-2.08-66.752 0-109.088 48.544-123.04 67.296V160H160v352h114.496V320s86.528-120.512 123.04-32v224H512V274.464c0-53.184-36.448-97.504-85.632-110.336z" />
      <circle cx="56" cy="56" r="56" />
    </svg>
  </IconWrapper>
)

export default LinkedInIcon
