import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import {
  StyledNews,
  StyledNewsImgWrapper,
  StyledNewDate,
  StyledNewsHeader,
  StyledNewsContent,
  StyledNewDesc,
  StyledNewsReadMore,
} from './styles'
import Image from '../Image/Image'
import { fluidPropType } from '../../constants/proptypes'
import { changeLinksPath, formatDate } from '../../common/utils'

const News = ({ fluid, date, label, description, to, anim }) => {
  const newDate = new Date(date)

  return (
    <StyledNews to={to} {...(anim && { className: 'uiAnimCustomBottom' })}>
      <StyledNewsImgWrapper>
        <Image fluid={fluid} />
      </StyledNewsImgWrapper>
      <StyledNewsContent>
        <StyledNewDate>{formatDate(newDate)}</StyledNewDate>
        <StyledNewsHeader
          as="h3"
          fontSize="1.5rem"
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
        {description && (
          <StyledNewDesc
            as="div"
            dangerouslySetInnerHTML={{
              __html: changeLinksPath(description),
            }}
          />
        )}
        <StyledNewsReadMore>Read Article</StyledNewsReadMore>
      </StyledNewsContent>
    </StyledNews>
  )
}

export default React.memo(News)

News.defaultProps = {
  anim: false,
}

News.propTypes = {
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  fluid: fluidPropType,
  anim: PropTypes.bool,
}

export const query = graphql`
  fragment postFragment on wp_Post {
    id
    slug
    title
    pseudoExcerpt
    date
    featuredImage {
      mediaItemUrl
      imageFile {
        childImageSharp {
          fluid(sizes: "(max-width: 767px) calc(100vw - 60px), 360px") {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
