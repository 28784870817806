import styled from 'styled-components'

import media from '../../common/MediaQueries'
import {
  transition,
  decorReverseLink,
  decorHoverReverseLink,
} from '../../common/mixins'

const StyledContent = styled.div`
  width: 100%;
  max-width: 46.875rem;
  margin: 2.5rem auto 0 auto;
  letter-spacing: 0.32px;
  line-height: 1.6;
  font-size: 1.125rem;

  img {
    margin: 1rem 0;
  }

  a:not([class]) {
    color: ${({ theme }) => theme.colors.yellow};
    ${decorReverseLink}

    &:hover {
      ${decorHoverReverseLink}
    }
  }

  p:not([class]):first-child,
  h3:not([class]) {
    font-size: 1.25rem;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin-top: 1.875rem;
    margin-bottom: 1.5rem;
    line-height: 1.58;
    letter-spacing: 0.42px;
  }

  h2:not([class]) {
    color: ${({ theme }) => theme.colors.yellow};
    margin-bottom: 1.5rem;
    letter-spacing: 0.64px;
    font-size: 1.75rem;
    font-family: ${({ theme }) => theme.fontFamilies.oswald};
  }

  a:not([class]) {
    ${transition}
    &:hover {
      color: ${({ theme }) => theme.colors.yellow};
    }
  }

  p:not([class]) {
    letter-spacing: 0.32px;
    line-height: 1.6;
    font-size: 1.125rem;
  }

  div:not([class]) + p:not([class]),
  p:not([class]) + p:not([class]) {
    margin-top: 1.875rem;
  }

  h2:not([class]) + h3:not([class]),
  p:not([class]) + h2:not([class]),
  p:not([class]) + h3:not([class]) {
    margin-top: 2.5rem;
  }

  blockquote {
    position: relative;
    max-width: 41rem;
    width: 100%;
    margin: 1.875rem 0;
    letter-spacing: 0.4px;
    font-size: 1.375rem;
    line-height: 1.59;
    padding-left: 0.875rem;

    p:not([class]) {
      letter-spacing: 0.4px;
      font-size: 1.375rem;
      line-height: 1.59;
    }

    &::before {
      width: 0.0625rem;
      height: calc(100% - 1rem);
      content: '';
      display: block;
      position: absolute;
      top: 0.5rem;
      left: 0;
      background: ${({ theme }) => theme.colors.yellow};
    }

    p:not([class]):first-child {
      margin: 0;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    }
  }

  strong:not([class]) {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  @media ${media.tabletSmall} {
    margin-top: 3rem;

    p:not([class]):first-child {
      font-size: 1.5rem;
      margin-top: 3rem;
    }

    h2:not([class]) {
      font-size: 2.25rem;
    }

    h2:not([class]) + h3:not([class]),
    p:not([class]) + h2:not([class]) {
      margin-top: 3.125rem;
    }
  }
`

export { StyledContent }
