import React, { useState } from 'react'

import NewsListContext from '../contexts/NewsListContext'

const NewsListProvider = ({ list = [], children }) => {
  const [newsList, setNewsList] = useState(list)

  return (
    <NewsListContext.Provider value={{ newsList, setNewsList }}>
      {children}
    </NewsListContext.Provider>
  )
}

export default NewsListProvider
