import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledTagsList,
  StyledTagsItem,
  StyledTag,
  StyledTagLabel,
} from './styles'

const Tags = ({ tags }) => (
  <StyledTagsList>
    {tags.map(({ tag, url }, index) => (
      <StyledTagsItem key={index}>
        <StyledTag to={url}>
          <StyledTagLabel>{tag}</StyledTagLabel>
        </StyledTag>
      </StyledTagsItem>
    ))}
  </StyledTagsList>
)

export default Tags

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
