import styled from 'styled-components'
import media, { customMedia } from '../../../common/MediaQueries'

import {
  StyledShadowedArrowPrev,
  StyledShadowedArrowNext,
} from '../SliderNavigation/ShadowedArrows/styles'
import { StyledNews } from '../../News/styles'
import { StyledSliderSlide } from '../../Sliders/Slider/styles'

const StyledNewsSlider = styled.div`
  .slick-track {
    flex-direction: column;
  }

  .slick-slide {
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  ${StyledSliderSlide} {
    padding-bottom: 0.25rem;
  }

  ${StyledShadowedArrowPrev},
  ${StyledShadowedArrowNext} {
    color: ${({ theme }) => theme.colors.yellow};
  }

  @media ${customMedia.maxPhone} {
    .slick-list {
      overflow: visible;
    }
  }

  ${StyledShadowedArrowPrev} {
    @media ${media.phone} {
      left: -0.375rem;
    }

    @media ${media.desktop} {
      left: -1.5rem;
    }
  }

  ${StyledShadowedArrowNext} {
    @media ${media.phone} {
      right: -0.375rem;
    }

    @media ${media.desktop} {
      right: -1.5rem;
    }
  }

  @media ${media.phone} {
    margin: 0 -0.625rem;
    position: relative;

    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0.625rem;
      height: 100%;
      background: inherit;
      z-index: 1;
    }
    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }

    .slick-track {
      flex-direction: row;
    }

    .slick-slide {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @media ${media.phone} {
    ${StyledNews} {
      margin: 0 0.625rem;
    }
  }

  @media ${media.tablet} {
    margin: 0 -0.9375rem;

    &::before,
    &::after {
      width: 0.9375rem;
    }

    ${StyledNews} {
      margin: 0.125rem 0.9375rem 0.9375rem 0.9375rem;
    }
  }
`

export { StyledNewsSlider }
