import styled from 'styled-components'
import Link from 'gatsby-link'
import { rgba } from 'polished'

import {
  decorReverseLink,
  decorHoverReverseLink,
  transition,
  date,
} from '../../common/mixins'

import { Body, Typo2 } from '../Typography/Typography'
import { StyledImage } from '../Image/styles'

const StyledNewsImgWrapper = styled.div`
  position: relative;

  ${StyledImage} {
    ${transition}
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.black};
  }
`

const StyledNewDate = styled.time`
  ${date}
`

const StyledNewsHeader = styled(Typo2)`
  margin-bottom: 1.25rem;
  width: 100%;
  ${transition}
`

const StyledNewsContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  width: 100%;
  padding: 1.875rem 1.875rem 2rem 1.875rem;
  z-index: 1;
`

const StyledNewDesc = styled(Body)`
  width: 100%;
  margin-bottom: 1.5rem;
  flex-grow: 1;
`

const StyledNewsReadMore = styled.p`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.yellow};
  ${decorReverseLink}
`

const StyledNews = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.black};
  box-shadow: 0 0 0.3125rem 0 ${({ theme }) => rgba(theme.colors.black, 0.07)};
  cursor: pointer;
  ${transition}

  &:focus {
    outline: none;
  }

  &:hover {
    ${StyledNewsHeader} {
      color: ${({ theme }) => theme.colors.yellow};
    }
    ${StyledImage} {
      opacity: 0.75;
      transform: scale3d(1.03, 1.03, 1.03) translateZ(0, 0, 0);
    }
    ${StyledNewsReadMore} {
      ${decorHoverReverseLink}
    }
  }
`

export {
  StyledNews,
  StyledNewsImgWrapper,
  StyledNewDate,
  StyledNewsHeader,
  StyledNewsContent,
  StyledNewDesc,
  StyledNewsReadMore,
}
