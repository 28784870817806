// React Slick

import React from 'react'
import PropTypes from 'prop-types'

import Slider from '../Slider/Slider'
import News from '../../News/News'
import { fluidPropType } from '../../../constants/proptypes'
import { StyledNewsSlider } from './styles'
import defaultSliderOptions from '../../../constants/defaultSliderConfig'
import {
  PrevButton,
  NextButton,
} from '../SliderNavigation/ShadowedArrows/ShadowedArrows'

const sliderOptions = {
  ...defaultSliderOptions,
  slidesToShow: 3,
  prevArrow: <PrevButton />,
  nextArrow: <NextButton />,
  responsive: [
    {
      breakpoint: 1050,
      settings: {
        infinite: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        infinite: false,
        slidesToShow: 1,
        unslick: true,
      },
    },
  ],
}

const NewsSlider = ({ news, as }) => (
  <StyledNewsSlider>
    <Slider
      sliderOptions={sliderOptions}
      slidesArray={news}
      slideComponent={News}
      as={as}
    />
  </StyledNewsSlider>
)

export default NewsSlider

NewsSlider.propTypes = {
  as: PropTypes.string,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      fluid: fluidPropType,
    }),
  ),
}
