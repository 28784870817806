import React from 'react'

import { IconWrapper } from '../../common/mixins'

const MailIcon = () => (
  <IconWrapper width="1.4375">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M10.688 95.156C80.958 154.667 204.26 259.365 240.5 292.01c4.865 4.406 10.083 6.646 15.5 6.646 5.406 0 10.615-2.219 15.469-6.604 36.271-32.677 159.573-137.385 229.844-196.896 4.375-3.698 5.042-10.198 1.5-14.719C494.625 69.99 482.417 64 469.333 64H42.667c-13.083 0-25.292 5.99-33.479 16.438-3.542 4.52-2.875 11.02 1.5 14.718z" />
      <path d="M505.813 127.406c-3.781-1.76-8.229-1.146-11.375 1.542-77.928 66.062-177.386 150.74-208.678 178.937-17.563 15.854-41.938 15.854-59.542-.021-33.354-30.052-145.042-125-208.656-178.917-3.167-2.688-7.625-3.281-11.375-1.542C2.417 129.156 0 132.927 0 137.083v268.25C0 428.865 19.135 448 42.667 448h426.667C492.865 448 512 428.865 512 405.333v-268.25c0-4.156-2.417-7.937-6.187-9.677z" />
    </svg>
  </IconWrapper>
)

export default MailIcon
